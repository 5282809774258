<template>
  <vx-card title="Edit Visit Plan">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          readonly="true"
          :value="
            this.selectedTerritory
              ? this.selectedTerritory.code + ' ' + this.selectedTerritory.name
              : ''
          "
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Plan</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          readonly="true"
          :value="
            this.selectedRoutePlan
              ? this.selectedRoutePlan.Code +
                ' ' +
                this.selectedRoutePlan.ScheduleType
              : ''
          "
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Assignment</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          readonly="true"
          :value="
            this.selectedRouteAssignment
              ? this.selectedRouteAssignment.Code
              : ''
          "
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          readonly="true"
          :value="
            this.dateFrom != this.dateTo
              ? this.dateFrom + ' - ' + this.dateTo
              : this.dateFrom
          "
        />
      </div>
    </div>

    <!-- maps -->
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <l-map style="height: 400px" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            :lat-lng="customerAddress.latLng"
            :key="index"
            v-for="(customerAddress, index) in customerAddresses"
          >
            <l-tooltip :options="{ permanent: true, interactive: true }">
              <div>
                <b>{{ customerAddress.label }}</b
                ><br />{{ customerAddress.address }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-map>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-table :data="this.data" :border="1">
          <template slot="thead">
            <!-- <vs-th></vs-th> -->
            <vs-th width="100px">Date</vs-th>
            <vs-th width="200px">Sales</vs-th>
            <vs-th width="400px">Customer</vs-th>
            <vs-th width="100px">Dstr Channel</vs-th>
            <vs-th width="400px">Customer Ship-to</vs-th>
            <vs-th width="400px">Ship-to Address</vs-th>
            <vs-th width="200px">Task</vs-th>
            <vs-th width="50px"></vs-th>
          </template>
          <template slot-scope="{ data }">
            <tr :data="tr" :key="indexParent" v-for="(tr, indexParent) in data">
              <td class="td vs-table--td" width="100px">
                {{ data[indexParent].date }}
              </td>
              <td class="td vs-table--td" width="200px">
                <v-select
                  :disabled="data[indexParent].is_pulled"
                  :filterable="false"
                  :options="data[indexParent].option_personal"
                  v-model="data[indexParent].selected_personal"
                  @input="
                    (val) => {
                      onChangePersonal(val, indexParent);
                    }
                  "
                  @search="
                    (search, loading) => {
                      onSearchPersonal(search, loading, indexParent);
                    }
                  "
                />
              </td>
              <td colspan="6">
                <table>
                  <draggable
                    :list="data[indexParent].customer_lines"
                    tag="tbody"
                  >
                    <tr
                      v-for="(child, indexChild) in data[indexParent]
                        .customer_lines"
                      :key="indexChild"
                    >
                      <td class="td vs-table--td" width="400px">
                        <v-select
                          label="id"
                          :disabled="data[indexParent].is_pulled"
                          :filterable="false"
                          :options="
                            data[indexParent].customer_lines[indexChild]
                              .option_customer
                          "
                          v-model="
                            data[indexParent].customer_lines[indexChild]
                              .selected_customer
                          "
                          @input="
                            (val) => {
                              onChangeCustomer(val, indexParent, indexChild);
                            }
                          "
                          @search="
                            (search, loading) => {
                              onSearchCustomer(
                                search,
                                loading,
                                indexParent,
                                indexChild
                              );
                            }
                          "
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.code }} {{ option.name }}
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            {{ option.code }} {{ option.name }}
                          </template>
                        </v-select>
                      </td>
                      <td width="100px">
                        <input
                          type="text"
                          readonly="readonly"
                          class="vs-inputx vs-input--input normal"
                          style="border: 1px solid rgba(0, 0, 0, 0.2)"
                          :value="
                            getCustomerCategoryName(
                              data[indexParent].customer_lines[indexChild]
                                .selected_customer
                            )
                          "
                        />
                      </td>
                      <td width="400px">
                        <v-select
                          label="id"
                          :disabled="data[indexParent].is_pulled"
                          :filterable="false"
                          :options="
                            data[indexParent].customer_lines[indexChild]
                              .option_customer_address
                          "
                          v-model="
                            data[indexParent].customer_lines[indexChild]
                              .selected_customer_address
                          "
                          @input="
                            (val) => {
                              onChangeCustomerAddress(
                                val,
                                indexParent,
                                indexChild
                              );
                            }
                          "
                          @search="
                            (search, loading) => {
                              onSearchCustomerAddress(
                                search,
                                loading,
                                data[indexParent].customer_lines[indexChild]
                                  .selected_customer.id,
                                indexParent,
                                indexChild
                              );
                            }
                          "
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.code }} {{ option.contact_name }}
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            {{ option.code }} {{ option.contact_name }}
                          </template>
                        </v-select>
                      </td>
                      <td width="400px">
                        <input
                          type="text"
                          readonly="readonly"
                          class="vs-inputx vs-input--input normal"
                          style="border: 1px solid rgba(0, 0, 0, 0.2)"
                          :value="
                            getCustomerAddressAddress(
                              data[indexParent].customer_lines[indexChild]
                                .selected_customer_address
                            )
                          "
                        />
                      </td>
                      <td
                        width="200px"
                        class="td vs-table--td"
                        style="text-align: center"
                      >
                        <v-select
                          :disabled="data[indexParent].is_pulled"
                          :filterable="true"
                          :options="optionSalesTask"
                          placeholder="Default"
                          multiple
                          v-model="
                            data[indexParent].customer_lines[indexChild]
                              .selected_sales_task
                          "
                        >
                        </v-select>
                      </td>
                      <td
                        width="50px"
                        class="td vs-table--td"
                        style="text-align: center"
                      >
                        <arrow-up-down-bold :size="15" />
                      </td>
                      <td
                        width="75px"
                        class="td vs-table--td"
                        style="text-align: center"
                      >
                        <div class="vx-input-group flex">
                          <vs-button
                            v-if="
                              data[indexParent].customer_lines.length > 1 &&
                              !data[indexParent].is_pulled
                            "
                            @click.stop="
                              removeCustomerRow(indexParent, indexChild)
                            "
                            size="small"
                            color="danger"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="margin-right: 5px"
                            title="Remove Customer"
                          />

                          <vs-button
                            v-if="
                              data[indexParent].customer_lines.length - 1 ==
                                indexChild && !data[indexParent].is_pulled
                            "
                            @click.stop="addCustomerRow(indexParent)"
                            size="small"
                            color="success"
                            icon-pack="feather"
                            icon="icon-plus"
                            title="Add Customer"
                          />
                        </div>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </td>
            </tr>
          </template>
        </vs-table>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div
          style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          "
        >
          <vs-button
            v-if="hasPermission('edit')"
            class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleBack"
            >Back</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";
import _ from "lodash";
import vSelect from "vue-select";

import "leaflet/dist/leaflet.css";

import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/visit-plan-generate",

        customerVisitPlanID: null,
        selectedTerritory: null,
        selectedRoutePlan: null,
        selectedRouteAssignment: null,
        dateFrom: null,
        dateTo: null,
        optionSalesTask: [],
        customerAddresses: [],

        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 15,
        center: [-6.2, 106.816666],

        data: [],
        defaultData: [
          {
            customer_visit_plan_line_id: null,
            date: null,
            selected_personal: null,
            option_personal: [],
            customer_lines: [
              {
                customer_visit_id: null,
                customer_id: null,
                customer_address_id: null,
                selected_customer: null,
                option_customer: [],
                selected_customer_address: null,
                option_customer_address: [],
                selected_sales_task: [],
              },
            ],
          },
        ],
      };
    },
    handleBack() {
      this.$router.push({
        name: "visit-plan-generate",
      });
    },
    handleSubmit() {
      let customerVisitPlanLines = [];
      this.data.forEach((el) => {
        if (!el.is_pulled) {
          let customerLines = [];
          el.customer_lines.forEach((customerLine, index) => {
            var salesTaskIDs = [];
            customerLine.selected_sales_task.forEach((salesTask) => {
              salesTaskIDs.push(salesTask.id);
            });

            if (
              !customerLine.customer_id ||
              !customerLine.customer_address_id
            ) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "Customer and Customer Address is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              return false;
            }

            customerLines.push({
              customer_visit_id: customerLine.customer_visit_id,
              customer_id: customerLine.customer_id,
              customer_address_id: customerLine.customer_address_id,
              sales_task_ids: salesTaskIDs,
              sequence: index + 1,
            });
          });

          if (!el.selected_personal) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Sales is required",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return false;
          }

          customerVisitPlanLines.push({
            customer_visit_plan_line_id: el.customer_visit_plan_line_id,
            personal_id: el.selected_personal.personal_id,
            customer_lines: customerLines,
          });
        }
      });

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + this.customerVisitPlanID + "/update", {
              customer_visit_plan_lines: customerVisitPlanLines,
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    getCustomerVisitPlan() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.customerVisitPlanID + "/edit")
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data) {
              this.data = resp.data.data;
              this.selectedTerritory = resp.data.territory;
              this.selectedRoutePlan = resp.data.route_plan;
              this.selectedRouteAssignment = resp.data.route_assignment;
              this.dateFrom = resp.data.date_from;
              this.dateTo = resp.data.date_to;
            } else {
              this.data = this.defaultData;
              this.selectedTerritory = null;
              this.selectedRoutePlan = null;
              this.selectedRouteAssignment = null;
              this.dateFrom = null;
              this.dateTo = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    onChangePersonal(val, index) {
      this.data[index].selected_personal = val;
    },
    onChangeCustomer(val, indexParent, indexChild) {
      let cek = this.data[indexParent].customer_lines.filter((el, index) => {
        if (el.selected_customer && val) {
          if (el.selected_customer.id == val.id && index != indexChild) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.data[indexParent].customer_lines[indexChild].selected_customer =
          null;
        alert("Customer already add");
      }
      this.data[indexParent].customer_lines[indexChild].selected_customer = val;

      this.setCustomerAddress(
        this.data[indexParent].customer_lines[indexChild],
        indexParent,
        indexChild
      );
    },
    onChangeCustomerAddress(val, indexParent, indexChild) {
      if (val) {
        this.optionRouteGroupLine[indexParent].customer_lines[
          indexChild
        ].customer_address_id = val.id;
      }
    },
    setCustomerAddress(element, indexParent, indexChild) {
      if (element.selected_customer) {
        if (element.selected_customer.id) {
          if (element.selected_customer.id != element.customer_id) {
            if (this.selectedTerritory.id) {
              this.$http
                .get(this.baseUrl + "/customer-address", {
                  params: {
                    order: "id",
                    sort: "asc",
                    territory_id: this.selectedTerritory.id,
                    // zone_id: this.selectedZone.id,
                    customer_id: element.selected_customer.id,
                  },
                })
                .then((resp) => {
                  if (resp.code == 200) {
                    let customerAddresses = resp.data.records;
                    this.data[indexParent].customer_lines[
                      indexChild
                    ].option_customer_address = customerAddresses;

                    if (customerAddresses.length > 0) {
                      this.data[indexParent].customer_lines[
                        indexChild
                      ].selected_customer_address = customerAddresses[0];
                      this.data[indexParent].customer_lines[
                        indexChild
                      ].customer_address_id = customerAddresses[0].id;
                    }

                    this.data[indexParent].customer_lines[
                      indexChild
                    ].customer_id = element.selected_customer.id;
                  } else {
                    this.resetSelectedCustomerAddress(indexParent, indexChild);
                  }
                });
            } else {
              this.resetSelectedCustomerAddress(indexParent, indexChild);
            }
          }
        } else {
          this.resetSelectedCustomerAddress(indexParent, indexChild);
        }
      } else {
        this.resetSelectedCustomerAddress(indexParent, indexChild);
      }
    },
    addCustomerRow(indexParent) {
      this.data[indexParent].customer_lines.push({
        customer_visit_id: null,
        customer_id: null,
        customer_address_id: null,
        selected_customer: null,
        option_customer: [],
        selected_customer_address: null,
        option_customer_address: [],
        selected_sales_task: [],
      });
    },
    removeCustomerRow(indexParent, indexChild) {
      this.data[indexParent].customer_lines.splice(indexChild, 1);
    },
    getCustomerCategoryName(selectedCustomer) {
      return selectedCustomer ? selectedCustomer.customer_category_name : "";
    },
    getCustomerAddressAddress(selectedCustomerAddress) {
      let address = "";
      if (selectedCustomerAddress) {
        if (selectedCustomerAddress.address) {
          address += selectedCustomerAddress.address;
        }

        if (selectedCustomerAddress.sub_district) {
          address += ", " + selectedCustomerAddress.sub_district;
        }

        if (selectedCustomerAddress.district) {
          address += ", " + selectedCustomerAddress.district;
        }

        if (selectedCustomerAddress.city) {
          address += ", " + selectedCustomerAddress.city;
        }

        if (selectedCustomerAddress.province) {
          address += ", " + selectedCustomerAddress.province;
        }

        if (selectedCustomerAddress.Indonesia) {
          address += ", " + selectedCustomerAddress.Indonesia;
        }

        if (selectedCustomerAddress.postal_code) {
          address += " " + selectedCustomerAddress.postal_code;
        }
      }

      return address;
    },
    getSalesTask() {
      this.$http
        .get(this.baseUrl + "/sales-task", {
          params: {
            length: 0,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSalesTask = resp.data.records;
            } else {
              this.optionSalesTask = [];
            }
          }
        });
    },

    resetSelectedCustomerAddress(indexParent, indexChild) {
      this.data[indexParent].customer_lines[
        indexChild
      ].option_customer_address = [];
      this.data[indexParent].customer_lines[
        indexChild
      ].selected_customer_address = null;

      this.data[indexParent].customer_lines[indexChild].customer_id = null;
    },
    onSearchPersonal(search, loading, index) {
      if (search.length && this.selectedTerritory) {
        loading(true);
        this.searcPersonal(loading, search, this, index);
      }
    },
    onSearchCustomer(search, loading, indexParent, indexChild) {
      if (search.length) {
        loading(true);
        this.searchCustomer(loading, search, this, indexParent, indexChild);
      }
    },
    onSearchCustomerAddress(
      search,
      loading,
      customerID,
      indexParent,
      indexChild
    ) {
      if (search.length) {
        loading(true);
        this.searchCustomerAddress(
          loading,
          search,
          this,
          customerID,
          indexParent,
          indexChild
        );
      }
    },
    searcPersonal: _.debounce((loading, search, t, index) => {
      t.$http
        .get(t.baseUrl + "/sales", {
          params: {
            length: 10,
            search: search.trim(),
            territory_id: t.selectedTerritory.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                return (x.label = x.work_id_number + " " + x.name);
              });
              t.data[index].option_personal = resp.data.records;
            } else {
              t.data[index].option_personal = [];
            }
            loading(false);
          } else {
            t.data[index].option_personal = [];
            loading(false);
          }
        });
    }, 350),
    searchCustomer: _.debounce(
      (loading, search, t, indexParent, indexChild) => {
        if (t.selectedTerritory) {
          t.$http
            .get(t.baseUrl + "/customer", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                search: search,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  t.data[indexParent].customer_lines[
                    indexChild
                  ].option_customer = resp.data.records;
                } else {
                  t.data[indexParent].customer_lines[
                    indexChild
                  ].option_customer = [];
                }
                loading(false);
              } else {
                t.data[indexParent].customer_lines[indexChild].option_customer =
                  [];
                loading(false);
              }
            });
        }
      },
      350
    ),
    searchCustomerAddress: _.debounce(
      (loading, search, t, customerID, indexParent, indexChild) => {
        if (t.selectedTerritory.id && t.selectedZone.id && customerID) {
          t.$http
            .get(t.baseUrl + "/customer-address", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                zone_id: t.selectedZone.id,
                customer_id: customerID,
                search: search,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                t.data[indexParent].customer_lines[
                  indexChild
                ].option_customer_address = resp.data.records;
              } else {
                t.data[indexParent].customer_lines[
                  indexChild
                ].option_customer_address = [];
              }
              loading(false);
            });
        }
      },
      350
    ),
  },
  created() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      // Do something with the position
      this.center = [latitude, longitude];
      // console.log(this.center);
    };

    const error = (err) => {
      console.log(error);
    };

    // This will open permission popup
    navigator.geolocation.getCurrentPosition(success, error);

    this.getSalesTask();
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-visit-plan-generate");
    this.customerVisitPlanID = this.$route.params.id;

    this.getCustomerVisitPlan();
  },
  watch: {
    data: {
      handler: function (val) {
        console.log(val);
        let customerAddresses = [];
        val.forEach((v) => {
          v.customer_lines.forEach((customer_line) => {
            if (customer_line.selected_customer_address) {
              if (
                customer_line.selected_customer_address.latitude &&
                customer_line.selected_customer_address.longitude
              ) {
                customerAddresses.push({
                  label:
                    customer_line.selected_customer_address.code +
                    " " +
                    customer_line.selected_customer_address.contact_name,
                  latLng: [
                    parseFloat(
                      customer_line.selected_customer_address.latitude
                    ),
                    parseFloat(
                      customer_line.selected_customer_address.longitude
                    ),
                  ],
                  address: this.getCustomerAddressAddress(
                    customer_line.selected_customer_address
                  ),
                });

                this.center = [
                  parseFloat(customer_line.selected_customer_address.latitude),
                  parseFloat(customer_line.selected_customer_address.longitude),
                ];
              }
            }
          });
        });

        this.customerAddresses = customerAddresses;
        // console.log(customerAddresses);
      },
      deep: true,
    },
  },
};
</script>

<style>
.multiselect__input {
  z-index: 0;
}
.multiselect__content-wrapper {
  z-index: 10;
}
</style>

